<template>
  <tippy v-bind="tippyConfig">
    <template v-slot:trigger>
      <component
          :is="to ? 'router-link' : 'span'"
          :content="hint"
          class="tags__item"
          :class="{'_active': isActive, '_pr-10': !clearable}"
          :to="to"
      >
        <span @click="callbackInit">
          {{ name }}
          <icon
              v-if="isActive && clearable"
              class="tags__icon"
              name="clear"
              width="18px"
              height="18px"
              fill="#9D99AA"
          />
        </span>
      </component>
    </template>
  </tippy>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      default: undefined
    },
    name: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    callback: {
      type: Function
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasHint () {
      return !!this.hint
    },

    tippyConfig () {
      return {
        content: this.hint,
        theme: 'hint',
        animateFill: false,
        interactive: true,
        onShow: () => this.hasHint
      }
    }
  },

  methods: {
    callbackInit () {
      if (this.callback) this.callback()
    }
  }
}
</script>

<style scoped lang="scss">
.tags {
  &__item {
    display: flex;
    align-items: center;
    background: rgba(69, 81, 101, 0.07);
    padding: 4px 10px;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    border-radius: 100px;
    color: #455165;
    position: relative;
    margin-right: 8px;
    cursor: pointer;

    &.router-link-exact-active {
      color: #2979FF;
    }

    &:hover {
      background-color: #F1F7FD;
    }

    &._active {
      padding-right: 30px;
      color: #2979FF;
      background: #F1F7FD;
      cursor: default;

      &._pr-10 {
        padding-right: 10px;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 7px;
  }
}
</style>
